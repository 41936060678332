import React, { useRef } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Card, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import "./Partners.scss";
import Balmerol from "../icons/balmerol.png";
import Ceat from "../icons/ceat.png";
import Elofic from "../icons/elofic.png";
import Exide from "../icons/exide.png";
import Luxor from "../icons/luxor.png";
import Mars from "../icons/mars.png";
import Matrix from "../icons/matrix.png";
import Pix from "../icons/pix.png";
import Valvoline from "../icons/pngwing.com - 2024-08-13T153917.568.png";
import Pvn from "../icons/pvn.png";
import Skf from "../icons/skf.png";
import Sparkminda from "../icons/sparkminda.png";
import Srmt from "../icons/SRMT.png";
import Supercircle from "../icons/supercircle.png";
import Tex from "../icons/tex.png";
import Threebond from "../icons/threebond-1.png";
import Toyo from "../icons/toyo.png";
import Uniflex from "../icons/uniflex.png";
import Unominda from "../icons/unominda.png";
import Vijay from "../icons/vijay.png";
import Wurth from "../icons/wurth.png";

const blogs = [
  {
    img: Balmerol,
  },
  {
    img: Ceat,
  },
  {
    img:Elofic ,
  },
  {
    img:Exide ,
  },
  {
    img:Luxor ,
  },
  {
    img:Mars ,
  },
  {
    img:Matrix ,
  },
  {
    img:Pix ,
  },
  {
    img:Valvoline ,
  },
  {
    img:Pvn ,
  },
  {
    img:Skf ,
  },
  {
    img:Sparkminda ,
  },
  {
    img:Srmt ,
  },
  {
    img:Supercircle ,
  },
  {
    img:Tex ,
  },
  {
    img:Threebond ,
  },
  {
    img:Toyo ,
  },
  {
    img:Uniflex ,
  },
  {
    img:Unominda ,
  },
  {
    img:Vijay ,
  },
  {
    img:Wurth ,
  },
  
];

// Duplicate items to simulate infinite scrolling
const infiniteBlogs = [...blogs, ...blogs, ...blogs];

const BlogItem = ({ item }) => {
  const { img, title } = item;
  return (
    <Card className="border-0 ezy__blog16-card mt-5">
      <Card.Body className="text-center p-3">
        <div>
          <img src={img} alt={title} className="img-fluid round" />
        </div>
      </Card.Body>
    </Card>
  );
};

BlogItem.propTypes = {
  item: PropTypes.object.isRequired,
};

const Partner = () => {
  const splideRef = useRef(null);

  return (
    <div className='slide-part' style={{ width: '100%', margin: '0 auto' }}>
<Splide
  options={{
    type: 'loop', 
    direction: 'rtl',
    perPage: 4,
    autoplay: true,
    pauseOnHover: true,
    interval: 4000, 
    speed: 50000, 
    arrows: false, 
    pagination: false, 
    gap: '1rem',
    easing: 'ease-out', 
    drag: true, 
    breakpoints: {
      1200: {
        perPage: 3,
        gap: '1rem',
        speed: 15000,
      },
      768: {
        perPage: 2,
        gap: '0.5rem',
        speed: 12000,
      },
      468: {
        perPage: 1,
        gap: '0.5rem',
        speed: 3000,
      },
    },
  }}
  aria-label="Partner Slider"
  ref={splideRef}
>
  {infiniteBlogs.map((item, i) => (
    <SplideSlide key={i}>
      <Col xs={12} md={12}>
        <BlogItem item={item} />
      </Col>
    </SplideSlide>
  ))}
</Splide>

    </div>
  );
};

export default Partner;
