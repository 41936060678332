import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./About.scss";
import Aboutcar from "../images/about-car.avif";
import { NavLink } from "react-router-dom";
import { motion, AnimatePresence, useInView } from "framer-motion";
import { useRef } from "react";

const Feature28 = () => {
  const ref = useRef(null);
  const inView = useInView(ref, { triggerOnce: true, threshold: 0.1 });

  return (
    <AnimatePresence>
      <motion.section
        ref={ref}
        initial={{ opacity: 0 }}
        animate={inView ? { opacity: 1 } : {}}
        exit={{ opacity: 0 }}
        className="ezy__featured28 light"
      >
        {/* Animate the background shape */}
        <motion.div
          className="ezy__featured28-shape d-none d-lg-block"
          initial={{ scale: 0.5, opacity: 0 }}
          animate={inView ? { scale: 1, opacity: 1 } : {}}
          transition={{ type: "spring", stiffness: 100, delay: 0.2 }}
        />

        {/* Animate the container */}
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={inView ? { y: 0, opacity: 1 } : {}}
          transition={{ type: "spring", stiffness: 50, delay: 0.1 }}
        >
          <Container className="position-relative">
            <Row className="justify-content-between">
              <Col lg={5} className="pe-xl-5 text-center text-lg-start my-5">
                <motion.div
                  initial={{ x: "-100vw" }}
                  animate={inView ? { x: 0 } : {}}
                  transition={{ type: "spring", stiffness: 50, delay: 0.2 }}
                  className="text-center fs-1 mb-5"
                >
                  About Us
                </motion.div>
                <motion.h2
                  initial={{ x: "-100vw" }}
                  animate={inView ? { x: 0 } : {}}
                  transition={{ type: "spring", stiffness: 50, delay: 0.4 }}
                  className="ezy__featured28-heading mb-4 fw-bold"
                >
                  Best Service, you can find your own area.
                </motion.h2>
                <motion.p
                  initial={{ x: "-100vw" }}
                  animate={inView ? { x: 0 } : {}}
                  transition={{ type: "spring", stiffness: 50, delay: 0.6 }}
                  className="ezy__featured28-sub-heading"
                  style={{ color: "black" }}
                >
                  It’s easier to reach your savings goals when you have the right
                  place for your vehicle. Take a look and find the right one for you!
                </motion.p>
                <motion.div
                  initial={{ x: "-100vw" }}
                  animate={inView ? { x: 0 } : {}}
                  transition={{ type: "spring", stiffness: 50, delay: 0.8 }}
                >
                  <NavLink to="/products">
                    <Button
                      variant="primary"
                      className="ezy__featured28-btn mt-4 mt-md-5"
                    >
                      Explore more
                      <FontAwesomeIcon icon={faArrowRight} className="ms-1" />
                    </Button>
                  </NavLink>
                </motion.div>
              </Col>
              <Col lg={6}>
                <motion.div
                  initial={{ x: "100vw" }}
                  animate={inView ? { x: 0 } : {}}
                  transition={{ type: "spring", stiffness: 50, delay: 0.2 }}
                  className="ezy__featured28-bg-holder"
                  style={{
                    backgroundImage: `url(${Aboutcar})`,
                    borderRadius: "10px 50px 10px 10px",
                  }}
                />
              </Col>
            </Row>
          </Container>
        </motion.div>
      </motion.section>
    </AnimatePresence>
  );
};

export default Feature28;
