import React from "react";
import "./About.scss";
import backgroundImage from "../images/about.avif";
import Details from  "../../Components/Details/Details";
import Subdetails from "../../Components/Details/Subdetails"

const About = () => {
  const style = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  return (
    <>
      <div style={style}></div>
      <div
        className="container d-flex g-1 flex-column justify-content-center next-section"
        id="zoro"
      >
        <h1>Driving Excellence in <br /> <span style={{color:"lightgreen"}}>the Automotive Industry</span></h1>
        <p style={{ color: "white", fontWeight: "700" }}>
        Welcome to Malabar automobiles, a leader in the automotive industry dedicated to providing top-quality products and services <br /> that meet the diverse needs of drivers and automotive professionals worldwide.          </p>
      </div>
      <div className="container-fluid next-section">
      <Details/>
      </div>
      <div className="container-fluid next-section">
      <Subdetails/>
      </div>

    </>
  );
};

export default About;
