import React, { useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import "./Sub.scss";
import Valvoline from  "../images/valvoline.jpg";
import Ceat from "../images/ceat.gif";
import Exide from "../images/Exide.jpg";
import Tex from "../images/Tex.png";
import Skf from "../images/SKF.webp";
import Toyo from "../images/Toyo.png";
import Elofic from "../images/Elofic.jpg";
import Timken from "../images/Timken.jpg";
import Srmt from "../images/SRMT.png";
import Smart from "../images/spark.png";
import Uno from "../images/uno.png";
import Vijay from "../images/vijay.jpeg";
import Mars from "../images/mars.jpg";
import Luxor from "../images/luxor.jpg";
import Matrix from "../images/matrix.png";
import SuperCircle from "../images/SuperCircle.png";
import Wurth from "../images/Wurth.avif";
import PVN from "../images/pvn group.jpeg";
import Pix from "../images/pix.jpg";
// import Citizen from "../images/citizen start.jpg";
import Uniflex from "../images/uniflex.jpeg";
import Balmerol from "../images/BALMEROL-IMAGES.png";
import Threebond from "../images/threebond.png";
import Demo from "../images/working.jpg"


const serviceList = [
  {
    image: Valvoline,
    title: "Valvoline",
    description:"Valvoline is a renowned brand specializing in high-quality automotive products, offering a comprehensive range of engine oil, grease, coolant, and brake fluid. Designed to meet the diverse needs of vehicle maintenance and performance.Valvoline's products are available in various packaging sizes, ranging from 0.5 liters to 210 liters"
  },
  {
    image: Ceat,
    title: "Ceat types",
    description:"Ceat is a leading tire manufacturer, renowned for producing a wide range of high-performance tires suitable for 2-wheelers, 3-wheelers, and 4-wheelers. Known for their durability, safety, and superior grip, Ceat tires are designed to enhance the driving experience across various terrains and weather conditions"
  },
  {
    image: Exide,
    title: "Exide batteries",
    description:"Exide is a prominent manufacturer specializing in vehicle batteries. Known for their reliability and performance, Exide batteries cater to a wide range of vehicles, including cars, trucks, motorcycles,Their products are designed to provide optimal power, durability, and longevity, ensuring vehicles start efficiently and run smoothly"
  },
  {
    image: Tex,
    title: "Tex Automotive",
    description:
    "Tex automotives specializes in production and delivery of high quality products. Our offerings includes all types of engine oils ,grease,instant polish,and lube chain,all designed to meet the diverse needs of vehicle and machinery Wheather you need to ensure optimal engine performance,smooth mechanial operation,a pristine polished finish, or effective chain lube"
  },
  {
    image: Skf,
    title: "SKF",
    description:"SKF India private limited extensive product range includes ball bearings,tapered bearings grease,clutchplates,racer kits,brake pads, brake shoes, bearing kits, chains and sprockets. reowned for precision engineering and durability ,SKF india Limited's products are designed to enhance performance and reliability across various applications."
  },
  {
    image: Toyo,
    title: "Toyo Rubbers",
    description:`We offer you the  feeling of  sail instead of ride through  indian roads. our products  absorb the   strains and stress of your  vehicles and ensure long life with optimum maintanance. Let our products are rigid  or flexible but they are sure  enduring.And  here starts your driving to prosperity`
  },
  {
    image: Elofic,
    title: "Elofic",
    description:  "Elofic is one of India'slargest filter manufac­ turing companies producing a complete range of filtration and lubrication products: oil filters, air filters, fuel filters, hydraulic filters, coolants and lubricants and grease."
  },
  {
    image: Timken,
    title: "Timken",
    description:"Timken India Limited is a prominent manufacturer in the automobile sector, specializing in the production of ball bearings, tapered bearings, grease, and kits. Known for its high-quality products Timken India serves a wide range of applications in the automotive industry ensuring reli­ ability and performance."
  },
  {
    image: Srmt,
    title: "SRMT",
    description:"Founded in 1944, SRMT has established a command­ ing presence in the Indian automotive industry. Over its 75-year journey, SRMT has grown at a remarkable pace, evolving into a multifaceted industrial con­ glomerate. Renowned for its commitment to custom­ er service, innovation, and diversity, SRMT continues to set benchmarks in quality and performance."
  },
  {
    image: Smart,
    title: "Spark Minda",
    description:"Spark Minda has been a signifi­ cant player in the global automo­ tive industry for over six decades. As one of the prominent manu­ facturers of automotive compo­ nents for OEMs and Tier-I suppliers, Spark Minda offers a diverse range of products including door handles, wiper blades, lock kits, mini relays, and plot wiper blades. Their long-standing expertise and commitment to quality have es­ tablished them as a trusted name in the industry."
  },
  {
    image: Uno,
    title: "Uno Minda",
    description:"Uno Minda Limited is a leading global manu­ facturer of proprietary automotive solutions and systems supplying to OEMs as Tier-1.We have made important contribution to the automotive industry supply chain for more than six decades with our innovative products.Incepted in 1958, We are one of the leading manufacturers of automotive switching sys­ tems, automotive lighting systems, automotive acoustics systems, automotive seating systems and alloy wheels."
  },
  {
    image: Vijay,
    title: "Vijay",
    description:"VIJAy INDUSTRIES is one of a leading manufacturer of Parabolic Springs and Leaf springs in India. The mainly due to dynamic management, modern method of production, foresighted planning, scheduling & following International Standards to Satisfy our Customers"
  },
  {
    image: Mars,
    title: "Mars",
    description:"MARS'sspring bushes are designed to meet the demanding needs of vari­ ous automotive applications. Their commitment to excellence and inno­ vation has made them a trusted name in the industry. Our claim to success is hallmarked by the offered quality products that gained us hugerecognizance."
  },
  {
    image: Luxor,
    title: "Luxor",
    description:"LUXOR specializes in manufacturing quali­ ty components for heavy vehicles includ­ ing center bolts, U bolts, shims and jacks. Known for durability and precision, LUX­ OR's products are essential for maintaining the reliability and performance of heavy-duty vehicles. Their commitment to excellence has established them as a trusted name in the industry"
  },
  {
    image: Matrix,
    title: "Matrix Battteries",
    description:"Matrix Batteries offers an extensive range of high-performance, durable, and reliable power storage solutions for automotive, bikes, e-rickshaws, solar, and inverters. Com­mitted to meeting the unique needs of their customers, Matrix Batteries stands out for its dedication to delivering top-quality products in the power storage industry."
  },
  {
    image: SuperCircle,
    title: "Super Circle",
    description:"SUPER CIRCLEmanufactures a wide range of high quality, precision engineered auto­ motive parts. With over four decades of production expertise, the company is a leading name in friction products like brake pads, brake shoes, brake linings & roll brake linings, their parts like backing plates, unlined brake shoes & shims and engine parts like piston rings."
  },
  {
    image: Wurth,
    title: "Wurth",
    description:"WURTH is a leading supplier in the automotive after­ market industry, leveraging years of experience to offer complete solutions for both passenger and commercial vehicle segments. With a primary focus on workshop and bodyshop needs, WURTH is renowned for its high-quality, reliable maintenance products designed to meet the diverse demands of the automotive sector."
  },
  {
    image: PVN,
    title: "PVN Group",
    description:"PVN specializes in high quality spring U clamps, critical for securing leaf springs to a vehicle's axle. These U-shaped bolts are where the leaf springs are aligned and contribute to the stability and safety of the vehicle. Proper installation in­ volvesplacing the clamp on the leaf spring and axle and tightening the nuts to manufacturer's specifications. Regular maintenance checks are recommended to ensure ongoing safety and performance."
  },
  {
    image: Pix,
    title: "PIX",
    description:
      "Assumenda non repellendus distinctio nihil dicta sapiente, quibusdam maiores, illum at, aliquid blanditiis eligendi qui.Assumenda non repellendus distinctio nihil dicta sapiente, quibusdam maiores, illum at, aliquid blanditiis eligendi qui.",
  },
  {
    image: Demo,
    title: "Citizen Star",
    description:"Citizen Star, established in 1974 by Auto Traders, specializes in heavy vehicle bolts and jacks. With a commitment to redefining quality standards through firm beliefs and skillful approaches, Citi­ zen Star has gained widespread market acceptance. Over the years, the com­ pany has developed a wide range of over 10,000 chassis components, solidi­ fying its reputation for excellence in the auto parts industry."
  },
  {
    image: Uniflex,
    title: "Uniflex Autoparts",
    description:"Plastic polyurethane (PU) bushes are prized in modern vehicles for their durability and superior performance. Key components of the suspension system, they effectively absorb vibrations, enhancing ride comfort and reducing noise. PU bushes excel in harsh conditions and chemicals, offering improved handling precision and stability. They help maintain alignment and en­ hance road contact, leading to better handling and cornering. While they may transmit more road noise and have a higher initial cost, their longevity and low maintenance make them a valuable asset in contemporary vehicle design"
  },
  {
    image: Balmerol,
    title: "Balmerol Lubricants",
    description:"Balmer Lubricants, a key player in the greases and lubricants industry, specializes in high-quality engine oil, gear oil, and grease. With a goal to become a globally com­ petitive and rapidly growing lubrication solution provider, Balmer Lubricants is commit­ ted to building a strong brand with a trans-national presence. Established in 1867, Balmer Lawrie, the parent company, is a renowned transnational conglomerate with leadership in various sectors including logistics, steel barrels, specialty greases & lubri­ cants, travel & vacations, and leather chemicals."
  },
  {
    image: Threebond,
    title: "Threebond " ,
    description:"ThreeBond is highly regarded as a leading sealant manufacturer worldwide. Our compounds work as high-performing barri­ ers preventing the leakage of inner fluids through surfaces, joints or openings. When selecting a sealant, it is important to con­ sider the type of joint surface, reaction be­ tween the sealant and inner fluid, the re­ quired pressure resistance, and environ­ mental conditions of the application. As a trusted sealant manufacturer, Our range of sealants spans applications in a wide vari­ ety of industries."
  },
];

const ServiceItem = ({ service }) => (
  <div
    className="ezy__service14-card"
    style={{ backgroundImage: `url(${service.image})`, height:"100%",width:"100%" }}
  >
    <div className="ezy__service14-popup-card p-3 p-lg-5">
      <h4 className="ezy__service14-title fs-4 mb-3">{service.title}</h4>
      <p className="ezy__service14-content mb-0">{service.description}</p>
    </div>
  </div>
);

ServiceItem.propTypes = {
  service: PropTypes.object.isRequired,
};

const Service14 = () => {
  const [visibleItems, setVisibleItems] = useState(6);

  const handleViewMore = () => {
    if (visibleItems === 6) {
      setVisibleItems(12);
    } else if (visibleItems === 12) {
      setVisibleItems(serviceList.length);
    } else {
      setVisibleItems(6);
    }
  };

  return (
    <section className="ezy__service14 dark-gray">
      <Container>
        <Row className="justify-content-center mb-4 mb-md-5">
          <Col lg={6} className="text-center">
            <h2 className="ezy__service14-heading mb-4">Our Distributers</h2>
            <p className="ezy__service14-sub-heading mb-0">
              Our distributors are the backbone of our commitment to excellence,
              ensuring that the finest automotive products reach every corner of
              the market. With a deep understanding of the industry's demands
              and a passion for quality, our trusted partners bring top-tier
              brands and cutting-edge technology to your doorstep
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center text-center">
          {serviceList.slice(0, visibleItems).map((service, i) => (
            <Col sm={6} md={4} className="mt-3 mt-sm-4" key={i}>
              <ServiceItem service={service} />
            </Col>
          ))}
        </Row>
        <Row className="justify-content-center mt-4">
          <Col lg={3} className="text-center">
            <Button onClick={handleViewMore} variant="primary">
              {visibleItems >= serviceList.length ? "Show Less" : "View More"}
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Service14;
