import React, { useState } from "react";
import axios from "axios";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  faEnvelopeOpenText,
  faHome,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Form.scss";

const contactInfoList = [
  {
    icon: faHome,
    data: ["Calicut Road ,Pookkali bazar", "Tirur-7"],
  },
  {
    icon: faPhone,
    data: ["+91 9746465222", "+91 9746746366"],
  },
  {
    icon: faEnvelopeOpenText,
    data: ["inquiriesmalabargroup@gmail.com"],
  },
];

const ContactForm = () => {
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState({
    name: "",
    phno: "",
    email: "",
    message: "",
  });

  const notify = () => toast("Form submitted succesfully");


  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6003/malabar/contact-us",
        {
          name: data.name,
          phno: data.phno,
          email: data.email,
          message: data.message,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response Data:", response.data);
      if (response.data.status === true) {
        notify();

        setData({
          name: "",
          phno: "",
          email: "",
          message: "",
        });
        setValidated(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="d-flex align-items-center justify-content-center h-100 ms-lg-5">
      <Form
        className="w-100"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Form.Group className="mb-3 mt-2">
          <Form.Label htmlFor="ezy__contact12-form-name">Name</Form.Label>
          <Form.Control
            id="ezy__contact12-form-name"
            name="name"
            type="text"
            placeholder="John Doe"
            onChange={handleChange}
            value={data.name}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide your name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 mt-2">
          <Form.Label htmlFor="ezy__contact12-form-email">Email</Form.Label>
          <Form.Control
            id="ezy__contact12-form-email"
            name="email"
            type="email"
            placeholder="john@easyfrontend.com"
            onChange={handleChange}
            value={data.email}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email address.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 mt-2">
          <Form.Label htmlFor="ezy__contact12-form-phone">Mobile</Form.Label>
          <Form.Control
            id="ezy__contact12-form-phone"
            name="phno"
            type="tel"
            placeholder="Enter your mobile number"
            onChange={handleChange}
            value={data.phno}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid mobile number.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="ezy__contact12-form-message">Message</Form.Label>
          <Form.Control
            id="ezy__contact12-form-message"
            name="message"
            as="textarea"
            rows={3}
            placeholder="Enter your message"
            onChange={handleChange}
            value={data.message}
            required
          />
        </Form.Group>
        <div className="text-end">
          <Button variant="" type="submit" className="ezy__contact15-btn mb-3">
            Submit
          </Button>
        </div>
      </Form>
      <ToastContainer />
    </div>
  );
};

const ContactInfo = ({ contactInfoList }) => (
  <address className="mt-5">
    <div>
      {contactInfoList.map((info, i) => (
        <div
          className="ezy__contact14-contact d-flex align-items-center"
          key={i}
        >
          <div className="ezy__contact14-contact-icon">
            <FontAwesomeIcon icon={info.icon} />
          </div>
          <div className="ezy__contact14-contact-details">
            {info.data.map((row, j) => (
              <p className="mb-0" key={j}>
                {row}
              </p>
            ))}
          </div>
        </div>
      ))}
    </div>
  </address>
);

ContactInfo.propTypes = {
  contactInfoList: PropTypes.array.isRequired,
};

const ContactUs14 = () => {
  return (
    <section className="ezy__contact14 dark-gray" id="ezy__contact14">
      <Container>
        <Row>
          <Col xs={12} className="text-center text-sm-start">
            <h2 className="ezy__contact14-heading mb-0">Stay In Touch</h2>
            <p className="ezy__contact14-sub-heading ms-sm-4">
              Have any question? Drop us a message.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={5}>
            <ContactInfo contactInfoList={contactInfoList} />
          </Col>
          <Col xs={12} md={7} className="mt-5">
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactUs14;
