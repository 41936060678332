import React, { useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import "./Header.scss";
import Logo from "../images/malabar-logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { FaHome } from "react-icons/fa";
import { GrServices } from "react-icons/gr";
import { TbArrowRoundaboutLeft } from "react-icons/tb";
import { MdContactMail } from "react-icons/md";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  // const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // const handleScroll = () => {
  //   if (window.scrollY > 0) { 
  //     setIsScrolled(true);
  //   } else {
  //     setIsScrolled(false);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <div className={`header container-fluid d-flex justify-content-between p-3 `}>
      <div className="container d-flex justify-content-between" style={{padding:"unset"}}>
        <NavLink to="/">
          <div className="logo">
            <img src={Logo} alt="Logo" />
          </div>
        </NavLink>

        <ul className={`d-flex align-items-center ${isOpen ? "open" : ""}`}>
          <NavLink to="/">
            <li className={location.pathname === "/" ? "active-link" : ""} onClick={toggleMenu}>
              <FaHome className="icon-b" />
              Home
            </li>
          </NavLink>

          <NavLink to="/products">
            <li className={location.pathname === "/products" ? "active-link" : ""} onClick={toggleMenu}>
              <GrServices className="icon-b" />
              Products
            </li>
          </NavLink>

          <NavLink to="/about-us">
            <li className={location.pathname === "/about-us" ? "active-link" : ""} onClick={toggleMenu}>
              <TbArrowRoundaboutLeft className="icon-b" />
              About us
            </li>
          </NavLink>

          <NavLink to="/contact-us">
            <li className={location.pathname === "/contact-us" ? "active-link" : ""} onClick={toggleMenu}>
              <MdContactMail className="icon-b" />
              Contact
            </li>
          </NavLink>
        </ul>
        {isOpen ? (
          <AiOutlineClose
            className="ham"
            onClick={toggleMenu}
            color="red"
            size={30}
          />
        ) : (
          <GiHamburgerMenu
            className="ham"
            onClick={toggleMenu}
            color="lightblue"
            size={30}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
