import React, { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import DetsilImage from "../images/details.jpg";
import Hardwork from "../images/hardwork.jpg";
import { motion, useInView } from "framer-motion";
import "./Details.scss";

const stories = [
  {
    title: "The journey to relaxation.",
    description:
      "A pivotal moment in the company's history was the acquisition of the Valvoline distribution rights. This strategic move not only bolstered Mal- abar Traders' market presence but also led to the formation of Mala- bar Agencies. With Valvoline's reputable products in its portfolio, Mala- bar Agencies quickly became a significant player in the industry, facili- tating the company's growth and expansion. The synergies between Malabar Traders and Malabar Agencies culminated in their eventual merger, creating the Malabar Group, a robust entity with a diverse range of offerings in the automobile sector.",
    image: DetsilImage,
  },
  {
    title: "The way to heaven.",
    description:
      "Looking to the future, Malabar Group aims to expand its footprint across all parts of South India. This strategic move is driven by the com- pany's vision to bring its high-quality products and services to a broad- er audience. With a solid foundation and a commitment to innovation and customer satisfaction, Malabar Group is poised for continued growth and success in the years to come.",
    image: Hardwork,
  },
];

const StoryItem = ({ item, index }) => {
  const { title, description, image } = item;
  const ref = useRef(null);
  const inView = useInView(ref, { triggerOnce: true, threshold: 0.1 });

  return (
    <>
      <Col
        ref={ref}
        xs={12}
        md={5}
        className={index % 2 === 0 ? "order-2" : "order-2 order-md-1"}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : {}}
          transition={{ duration: 0.8, delay: 0.2 }}
          className={`d-flex flex-column justify-content-center ezy__about6-content ${
            index % 2 === 0 ? "ps-md-5" : "pe-md-5"
          }`}
        >
          <h4 className="fw-bold mb-3">{title}</h4>
          <p className="mb-0">{description}</p>
        </motion.div>
      </Col>
      <Col
        xs={12}
        md={5}
        className={`${
          index % 2 === 0 ? "order-1" : "order-1 order-md-2"
        } mb-4 mb-md-0`}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : {}}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <img src={image} alt={title} className="img-fluid" />
        </motion.div>
      </Col>
    </>
  );
};

StoryItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

const AboutUs6 = () => {
  return (
    <section className="ezy__about6 dark-gray" id="ezy__about6">
      <Container>
        <Row className="justify-content-center text-center mb-5">
          <Col xs={12} md={8}>
            <h2 className="ezy__about6-heading fw-bold mb-4">Our Story</h2>
            <p className="ezy__about6-sub-heading">
              Malabar Group, a stalwart in the automobile sector, boasts a legacy
              spanning 25 years. The company's journey began as Malabar Traders,
              initially focusing on the distribution of leaf springs for heavy
              vehicles. This humble beginning laid the foundation for the
              group's enduring success. Customer satisfaction catalyzed its
              expansion into multiple subsidiary companies, each catering to
              different facets of the automobile industry.
            </p>
          </Col>
        </Row>

        {stories.map((item, i) => (
          <Row
            className="align-items-center justify-content-center mt-5"
            key={i}
          >
            <StoryItem item={item} index={i + 1} />
          </Row>
        ))}
      </Container>
    </section>
  );
};

export default AboutUs6;
