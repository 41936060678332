import './App.css';
import Home from "./Components/Home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Service from './Pages/Services/Products';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';


function App() {
  return (
    < >
    <BrowserRouter>
        <Header /> 
       <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/products' element={<Service/>}/>
          <Route path='/about-us' element={<About/>}/>
          <Route path='/contact-us' element={<Contact/>}/>
      </Routes>
    <div className="container-fluid next-section bg-light" style={{padding:"unset"}}> 
    <Footer/>
    </div> 
    </BrowserRouter>
    </>
  );
}

export default App;
