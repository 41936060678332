import React, { useRef } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import './Splider.scss';
import { Card, Col } from "react-bootstrap";
import PropTypes from "prop-types";




const blogs = [
  {
    img: "https://cdn.easyfrontend.com/pictures/blog/blog_3.jpg",
    title: "The banking blog For All Ages.",
    text: "This is a factor in the economy of a nation, and the administration takes the major choices.",
  },
  {
    img: "https://cdn.easyfrontend.com/pictures/blog/blog_4.jpg",
    title: "The banking blog For All Ages.",
    text: "This is a factor in the economy of a nation, and the administration takes the major choices.",
  },
  {
    img: "https://cdn.easyfrontend.com/pictures/blog/blog_6.jpg",
    title: "The banking blog For All Ages.",
    text: "This is a factor in the economy of a nation, and the administration takes the major choices.",
  },
];

// Duplicate items to simulate infinite scrolling
const infiniteBlogs = [...blogs, ...blogs, ...blogs];

const BlogItem = ({ item }) => {
  const { img, title, text } = item;
  return (
    <Card className="border-0 ezy__blog16-card mt-5">
      <Card.Body className="text-center p-3">
        <div>
          <img src={img} alt={title} className="img-fluid" />
        </div>
        <h5 className="mt-4 mb-3 fw-bold">{title}</h5>
        <p className="px-lg-4">{text}</p>
      </Card.Body>
    </Card>
  );
};

BlogItem.propTypes = {
  item: PropTypes.object.isRequired,
};

const Partner = () => {
  const splideRef = useRef(null);

  return (
    <div className='slide-part' style={{ width: '100%', margin: '0 auto' }}>
      <Splide
  options={{
    type: 'loop', 
    perPage: 4,
    autoplay: true,
    pauseOnHover: true,
    interval: 2000, 
    speed: 20000, 
    arrows: false, 
    pagination: false, 
    gap: '1rem',
    easing: 'ease-out', 
    drag: true, 
    breakpoints: {
      1200: {
        perPage: 3,
        gap: '1rem',
        speed: 15000,
      },
      768: {
        perPage: 2,
        gap: '0.5rem',
        speed: 12000,
      },
      468: {
        perPage: 1,
        gap: '0.5rem',
        speed: 3000,
      },
    },
  }}
  aria-label="Partner Slider"
  ref={splideRef}
>
  {infiniteBlogs.map((item, i) => (
    <SplideSlide key={i}>
      <Col xs={12} md={12}>
        <BlogItem item={item} />
      </Col>
    </SplideSlide>
  ))}
</Splide>
    </div>
  );
};

export default Partner;
