import React from 'react';
import "./Contact.scss";
import backgroundImage from "../images/bikes.jpg";
import Form from "../../Components/Form/Form";


const Contact = () => {
    const style = {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      };
    
  return (
    <>
    <div style={style}></div>
    <div
        className="container d-flex g-1 flex-column justify-content-center next-section"
        id="zoro"
      >
        <h1>We are always here to help <br /> <span style={{color:"lightgreen"}}>Contact for any querys</span></h1>
        <p style={{ color: "white", fontWeight: "700" }}>
        Welcome to Malabar automobiles, a leader in the automotive industry dedicated to providing top-quality products and services <br /> that meet the diverse needs of drivers and automotive professionals worldwide.          </p>
      </div>  
      <div className="container-fluid next-section">
        <Form/>
      </div>
        </>
  );
}

export default Contact;
