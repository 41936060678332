import React, { useRef } from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "./Subdetails.scss";
import { motion, AnimatePresence, useInView } from "framer-motion";

const stories = [
  {
    text: "We partner with leading brands like valvoline,ceat and many more to bring you products.",
  },
  {
    text: "Your satisfaction is our priority. We are dedicated to providing exceptional customer service and support.",
  },
  {
    text: "We continuously explore the latest technologies and trends in the automotive industry to offer cutting-edge solutions.",
  },
  {
    text: "We are committed to environmentally friendly practices and products that contribute to a greener future.",
  },
];

const StoryItem = ({ item, index }) => {
  const { text } = item;
  return (
    <>
      <div className="mb-3">
        <FontAwesomeIcon icon={faCheck} className="text-primary me-2 small" />
        <span className="opacity-75 small">{text}</span>
      </div>
      {index !== stories.length && <hr className="my-2" />}
    </>
  );
};

StoryItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

const AboutUs9 = () => {
  const ref = useRef(null);
  const inView = useInView(ref, { triggerOnce: false, threshold: 0.1 });

  return (
    <AnimatePresence>
      <motion.section
        ref={ref}
        initial={{ opacity: 0 }}
        animate={inView ? { opacity: 1 } : {}}
        exit={{ opacity: 0 }}
        className="ezy__about9 gray"
        id="ezy__about9"
      >
        <Container>
          <Row className="align-items-center mb-5">
            <Col xs={12} lg={6}>
              <motion.div
                initial={{ x: "-100vw" }}
                animate={inView ? { x: 0 } : {}}
                transition={{ type: "spring", stiffness: 50, delay: 0.2 }}
              >
                <h6 className="ezy__about9-sub-heading">Hello Visitor,</h6>
                <h1 className="ezy__about9-heading " style={{ color: "black" }}>
                  Why Choose Us
                </h1>
                <hr className="ezy__about9-divider my-4" />
                <p
                  className="ezy__about9-sub-heading"
                  style={{ color: "black" }}
                >
                  We offer a comprehensive range of automotive products,
                  including [list of key products/services such as tires,
                  batteries, lubricants, car parts, etc.]. Each product is
                  carefully selected and tested to ensure it meets the highest
                  standards of quality and performance.
                </p>
                <Nav className="flex-column ezy__about9-features mt-5">
                  {stories.map((item, i) => (
                    <li key={i}>
                      <StoryItem item={item} index={i + 1} key={i} />
                    </li>
                  ))}
                </Nav>
              </motion.div>
            </Col>

            <Col xs={12} lg={6}>
              <motion.div
                initial={{ x: "-100vw" }}
                animate={inView ? { x: 0 } : {}}
                transition={{ type: "spring", stiffness: 50, delay: 0.8 }}
              >
                <div className="mt-5 mt-lg-0">
                  <img
                    src="https://cdn.easyfrontend.com/pictures/about/about9.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </motion.section>
    </AnimatePresence>
  );
};

export default AboutUs9;
