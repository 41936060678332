import React from "react";
import "./Products.scss";
import backgroundImage from "../images/working.jpg";
import Sub from "../../Components/Subproducts/Sub";

const Service = () => {
  const style = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  return (
    <>
      <div style={style}></div>
      <div className="container d-flex g-1 flex-column justify-content-center w-100 h-100 margin next-section " id="sanji" >
        <h1>
          Upgrade Your Ride: <br /> <span style={{color:"lightblue"}}>Shop the Best in Automotive Excellence</span> 
        </h1>
        <p style={{ color: "white", fontWeight: "700" }}>
        "Introducing Malabar automobiles, the ultimate solution for automotive enthusiasts who demand top-tier performance and reliability.     </p>
      </div>
      <div className="next-section mt-5">
        <Sub/>
      </div>
      
    </>
  );
};

export default Service;
