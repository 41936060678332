import React from "react";
import backgroundImage from "../images/work.jpg";
import Splider from "../Splider/Splider";
import About from "../About/About";
import { Button } from "react-bootstrap";
import "./Home.scss";
import { CgPhone, CgMail } from "react-icons/cg";
import { FaServicestack } from "react-icons/fa";
import { AiOutlineBranches } from "react-icons/ai";
import { SiPaloaltonetworks } from "react-icons/si";
import { SiRockylinux } from "react-icons/si";
import { MdDashboardCustomize } from "react-icons/md";
import Partner from "../Partners/Partners";
import { NavLink } from "react-router-dom";
// import { useRef } from "react";
import { motion, AnimatePresence, useInView } from "framer-motion";

const style = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
};

const styles = {
  backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
};

const Home = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <>
      <AnimatePresence>
        <div style={style}></div>
        <motion.div
          ref={ref}
          initial={{ opacity: 1 }}
          animate={inView ? { opacity: 1, transition: { duration: 0.5 } } : {}}
          exit={{ opacity: 1, transition: { duration: 0.5 } }}
          className="container-fluid w-100 h-100 d-flex flex-column"
          style={{ zIndex: "9999", position: "relative" }}
        >
          <div className="container d-flex g-1 flex-column justify-content-center w-100 h-50 margin">
            <h1>
              WELCOME TO <br /> <span style={{color:"lightblue"}}>MALABAR AUTOMOBILES</span>
            </h1>

            <p style={{ color: "white", fontWeight: "700" }}>
              YOUR TRUSTED PARTNER IN AUTOMOBILE DISTRIBUTION
            </p>

            <NavLink to="/products">
              <Button
                variant="primary"
                className="ezy__featured28-btn mt-4 mt-md-5"
              >
                Services
              </Button>{" "}
            </NavLink>
          </div>
        </motion.div>

        <div
          className="container next-section d-flex flex-column flex-md-row gap-2 align-items-center justify-content-center p-3 "
          style={{ marginTop: "150px" }}
        >
          <div
            className="d-flex flex-column w-100 w-md-50 align-items-center justify-content-center"
            style={{ backgroundColor: "white", height: "200px" }}
          >
            <h2>Call Us Now</h2>
            <div className="d-flex gap-3 align-items-center justify-content-center">
              <CgPhone size={40} />
              <div>
                <p style={{ color: "grey" }}>We are open: Mn-Fr: 10AM - 8PM</p>
                <h5 style={{ color: "red" }}>+91 974465222</h5>
                <h5 style={{ color: "red" }}>+91 9746746366</h5>
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-column w-100 w-md-50 align-items-center justify-content-center"
            style={{ backgroundColor: "black", height: "200px" }}
          >
            <h2 style={{ color: "white" }}>Mail Us Now</h2>
            <div className="d-flex gap-3 align-items-center justify-content-center">
              <CgMail size={40} color="white" />
              <div>
                <p style={{ color: "white" }}>We are open: Mn-Fr: 10AM - 8PM</p>
                <h5 style={{ color: "red" }}>
                  inquiriesmalabargroup@gmail.com
                </h5>
              </div>
            </div>
          </div>
        </div>
        <About />

        <div
          className="container-fluid points next-section p-5 "
          style={styles}
        >
          <div className="container-lg sub-points">
            <div className="items">
              <h1>32+</h1>
              <div className="d-flex gap-4 align-items-center justify-content-center">
                <FaServicestack color="blue" size={30} />
                <p>Years of excellence</p>
              </div>
            </div>
            <div className="items">
              <h1>32+</h1>
              <div className="d-flex gap-4 align-items-center justify-content-center">
                <AiOutlineBranches color="red" size={30} />
                <p>Years of excellence</p>
              </div>
            </div>
            <div className="items">
              <h1>32+</h1>
              <div className="d-flex gap-4 align-items-center justify-content-center">
                <SiPaloaltonetworks color="yellow" size={30} />
                <p>Years of excellence</p>
              </div>
            </div>
            <div className="items">
              <h1>32+</h1>
              <div className="d-flex gap-4 align-items-center justify-content-center">
                <SiRockylinux color="green" size={30} />
                <p>Years of excellence</p>
              </div>
            </div>
            <div className="items">
              <h1>3200+</h1>
              <div className="d-flex gap-4 align-items-center justify-content-center">
                <MdDashboardCustomize color="orange" size={30} />
                <p> Happy customers</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid next-section bg-white p-5">
          <h1 className="text-center mb-4">News & Events</h1>
          <Splider />
        </div>

        <div className="container-fluid next-section bg-white p-5">
          <h1 className="text-center mb-4">Our Partners</h1>
          <Partner />
        </div>

        <div
          className="container-fluid next-section"
          style={{ padding: "unset", height: "350px" }}
        >
          <iframe
            id="google-maps-embed"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3723.7058151472147!2d75.91126497480717!3d10.922327889235444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7b21d7d329b81%3A0x98a61f5700509110!2sMALABAR%20AGENCIES%20automobile%20distributor!5e1!3m2!1sen!2sin!4v1724145900566!5m2!1sen!2sin"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: "0" }}
            allowFullScreen=""
            aria-label="Google Maps embed showing IFZA Business Park"
            title="IFZA Business Park Location Map"
          ></iframe>
        </div>
      </AnimatePresence>
    </>
  );
};

export default Home;
