import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faInstagram,
	faFacebookSquare,
	
} from "@fortawesome/free-brands-svg-icons";
import PropTypes from "prop-types";
import "./Footer.scss"

const quickLinks = [
	{ value: "Terms & Conditions", href: "#!" },
	{ value: "Privacy Policy", href: "#!" },
];

const socialMedia = [
	{ value: "Facebook", href: "https://www.facebook.com/profile.php?id=61561395985489&mibextid=LQQJ4d" },
	{ value: "Instagram", href: "https://www.instagram.com/malabargroupofautomobiles?igsh=MTRybG9hMGR6MHc4Zw%3D%3D&utm_source=qr" },
	
];

const jobInfo = [
	{ value: "Home", href: "/" },
	{ value: "About", href: "/about-us" },
	{ value: "Service", href: "/service" },
	{ value: "Contact-us", href: "/contact-us" },
];

const sociaIcons = [
	{
		icon: faInstagram,
		href: "https://www.instagram.com/malabargroupofautomobiles?igsh=MTRybG9hMGR6MHc4Zw%3D%3D&utm_source=qr",
	},
	{
		icon: faFacebookSquare,
		href: "https://www.facebook.com/profile.php?id=61561395985489&mibextid=LQQJ4d",
	},

];

const QuickLinks = ({ qLink }) => (
	<li>
		<a href={qLink.href}>{qLink.value}</a>
	</li>
);
QuickLinks.propTypes = {
	qLink: PropTypes.object.isRequired,
};

const SocialMedia = ({ media }) => (
	<li>
		<a href={media.href}>{media.value}</a>
	</li>
);
SocialMedia.propTypes = {
	media: PropTypes.object.isRequired,
};

const JobInfo = ({ job }) => (
	<li>
		<a href={job.href}>{job.value}</a>
	</li>
);
JobInfo.propTypes = {
	job: PropTypes.object.isRequired,
};

const LanguageItem = ({ language }) => (
	<option value={language.value}>{language.text}</option>
);
LanguageItem.propTypes = {
	language: PropTypes.object.isRequired,
};

const NavigationItem = ({ item }) => (
	<a className="ms-2" href={item.href}>
		{item.value}
	</a>
);

NavigationItem.propTypes = {
	item: PropTypes.object.isRequired,
};

const SocialItem = ({ social }) => (
	<li>
		<a href={social.href}>
			<FontAwesomeIcon icon={social.icon} />
		</a>
	</li>
);

SocialItem.propTypes = {
	social: PropTypes.object.isRequired,
};

const Footer = () => {
	return (
		<section className="ezy__footer6 dark">
			<Container>
				<Row className="text-center text-sm-start">
					<Col lg={3} className="mt-4 mt-lg-0">
						<img
							src="/malabar-logo.png"
							height="30"
							alt=""
                            style={{height:"75px",width:"220px"}}
						/>
					</Col>
					<Col lg={9}>
						<Row>
							
							<Col sm={4} className="mt-4 mt-lg-0">
								<h5>Social Media</h5>
								<Nav className="flex-column ezy__footer6-quick-links">
									{socialMedia.map((media, i) => (
										<SocialMedia media={media} key={i} />
									))}
								</Nav>
							</Col>
							<Col sm={4} className="mt-4 mt-lg-0">
								<h5>Quick Links</h5>
								<Nav className="flex-column ezy__footer6-quick-links">
									{jobInfo.map((job, i) => (
										<JobInfo job={job} key={i} />
									))}
								</Nav>
							</Col>
							<Col sm={4} className="mt-4 mt-lg-0">
								<h5>Address</h5>
								<Nav className="flex-column ezy__footer6-quick-links">
								    <li><a href="#!">Malabar Group of Automobiles</a></li>
									<li><a href="#!">calicut Rd</a></li>
									<li><a href="#!">Pookkali Bazar Tirur-7</a></li>
								</Nav>
							</Col>
						</Row>

						<div>
							<Nav className="ezy__footer6-social d-inline-flex mt-2">
								{sociaIcons.map((social, i) => (
									<SocialItem social={social} key={i} />
								))}
							</Nav>
						</div>
						<div className="mt-3 opacity-50 ezy__footer6-copyright">
							<span>Copyright &copy; Malabar Automobiles, All rights reserved</span>
							{quickLinks.map((item, i) => (
								<NavigationItem item={item} key={i} />
							))}
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};
export default Footer;
